Runtime.System = {

	get: async function() {

		/* Default values */

        let data = {

			os: {},

			device: {
				screen:		{
					width: 		screen.width,
					height: 	screen.height,
                    dpr:        window.devicePixelRatio
				}
			},
        
			runtime: {
				type: 		'browser',
			}
		};
        

        /* Get basic information using the User-Agent string */
        
        switch (true) {
            case System.OS.Android:     data.os.name = 'Android'; break;
            case System.OS.Chromebook:  data.os.name = 'Chrome OS'; break;
            case System.OS.iOS:         data.os.name = 'iOS'; break;
            case System.OS.Mac:         data.os.name = 'macOS'; break;
            case System.OS.Windows:     data.os.name = 'Windows'; break;
            case System.OS.WebOS:       data.os.name = 'webOS'; break;
        }

        if (System.OS.Mac) {
            let matches = navigator.userAgent.match(/Mac OS X (\d+_\d+(_\d+)?)/);
            
            if (matches) {
                data.os.version = matches[1].replace(/_/g, '.');
            }
        }

        if (System.OS.iOS) {
            let matches = navigator.userAgent.match(/OS (\d+_\d+(_\d+)?)/);

            if (matches) {
                data.os.version = matches[1].replace(/_/g, '.');
            }
        }

        if (System.OS.Windows) {
            let matches = navigator.userAgent.match(/Windows NT (\d+\.\d+)/);

            if (matches) {
                data.os.version = matches[1];

                switch(data.os.version) {
                    case '5.1':		data.os.release = 'XP'; break;
                    case '6.0':		data.os.release = 'Vista'; break;
                    case '6.1':		data.os.release = '7'; break;
                    case '6.2':		data.os.release = '8'; break;
                    case '6.3':		data.os.release = '8.1'; break;
                    case '10.0':	data.os.release = '10'; break;
                }
            }
        }

        if (System.OS.Android) {
            let matches = navigator.userAgent.match(/Android (\d+(\.\d+)?)/);

            if (matches) {
                data.os.version = matches[1];
            }
        }

        switch (true) {
            case System.Engine.Blink:   
                data.runtime.engine = 'Blink'; 
                data.runtime.version = this.getToken(navigator.userAgent, 'Chrome', 1);
                break;

            case System.Engine.WebKit:  
                data.runtime.engine = 'WebKit'; 
                data.runtime.version = this.getToken(navigator.userAgent, 'Version', 2);
                break;

            case System.Engine.Gecko:   
                data.runtime.engine = 'Gecko'; 
                data.runtime.version = this.getToken(navigator.userAgent, 'Firefox', 1);
                break;

            case System.Engine.Trident: 
                data.runtime.engine = 'Trident'; 
                data.runtime.version = this.getToken(navigator.userAgent, 'Trident', 1);
                break;

            case System.Engine.Edge:    
                data.runtime.engine = 'EdgeHTML'; 
                data.runtime.version = this.getToken(navigator.userAgent, 'Edge', 1);
                break;
        }

        if (System.Features.Standalone) {
            data.runtime.installed = 'standalone';
        }

        if (System.Features.ProgressiveWebApp) {
            data.runtime.installed = 'pwa';
        }

        if (System.Features.TrustedWebActivity) {
            data.runtime.installed = 'twa';
        }


        /* Get detailed information using User-Agent Client Hints */

        let details;

        if (navigator.userAgentData) {
            try {
                details = await navigator.userAgentData.getHighEntropyValues([
                    'platform', 'platformVersion', 'architecture', 'bitness', 'model'
                ]);
            }
            catch(e) {            
            }

            if (details) {
                data.os.name = details.platform;

                if (data.os.name == 'Windows') {
                    const majorPlatformVersion = parseInt(details.platformVersion.split('.')[0]);

                    if (majorPlatformVersion >= 13) {
                        data.os.release = '11';
                    }
                    else if (majorPlatformVersion > 0) { 
                        data.os.release = '10';
                    }
                }
                else {
                    data.os.version = details.platformVersion;
                }

                if (details.architecture) {
                    data.os.arch = details.architecture;
                }

                if (details.bitness) {
                    data.os.bitness = details.bitness;
                }

                if (details.model) {
                    data.device.model = details.model;
                }
            }
        }
	
		return data;
	},

    getToken: function(userAgent, token, digits) {
        let matches = userAgent.match(new RegExp(token + '/([0-9]+)' + (digits == 2 ? '\.([0-9]+)' : '')));

        if (matches) {
            return digits == 1 ? parseInt(matches[1]) : parseFloat(matches[1] + '.' + matches[2]);
        }

        return null;
    }
}